<template>
  <div
    v-if="
      getUser.role.slug === 'super-administrateur' ||
      getUser.role.slug === 'responsable-commercial' ||
      getUser.role.slug === 'community-manager'
    "
  >
    <div
      v-if="isBlogArticlesLoading"
      class="d-flex justify-content-center mb-1"
      style="padding-top: 30vh"
    >
      <b-spinner
        variant="info"
        style="width: 3rem; height: 3rem"
        class="mr-1"
      />
    </div>
    <div v-else>
      <b-card no-body class="mt-2">
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- New article -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-2"
            >
              <b-button variant="primary" :to="{ name: 'new-article' }">
                <span class="text-nowrap font-medium-1">Nouvel article</span>
              </b-button>
            </b-col>
            <!-- End New article -->
            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  class="d-inline-block mr-1"
                  placeholder="Rechercher..."
                />
                <b-button variant="primary"> Filtrer </b-button>
              </div>
            </b-col>
            <!-- End Search -->
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start md-2 mr-25"
            >
              <b-button variant="primary" @click="reLoadArticlesAction()">
                <div v-if="isReLoadArticles">
                  <span> Chargement ... </span>
                  <b-spinner small />
                </div>
                <span v-else class="text-nowrap font-medium-1">Actualiser</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <!-- blogs -->
      <b-row class="blog-list-wrapper">
        <b-col v-for="blog in blogList" :key="blog.id" md="4">
          <b-card tag="article" no-body>
            <b-link
              :to="{
                name: 'blog-detail',
                params: { id: blog.slug.concat('-', blog.id) },
              }"
              @click="applyArticleDetail(blog)"
            >
              <b-img :src="blog.image" class="card-img-top" />
            </b-link>
            <b-card-body>
              <b-card-title>
                <b-link
                  :to="{
                    name: 'blog-detail',
                    params: { id: blog.slug.concat('-', blog.id) },
                  }"
                  class="blog-title-truncate text-body-heading"
                  @click="applyArticleDetail(blog)"
                >
                  {{ blog.title.slice(0, 15) }}....
                </b-link>
                <div
                  class="d-flex align-items-center justify-content-end mt-n2"
                >
                  <b-link>
                    <b-badge
                      pill
                      :variant="resolveArticleStatusVariant(blog.status)"
                    >
                      {{ resolveArticleStatusText(blog.status) }}
                    </b-badge>
                  </b-link>
                </div>
              </b-card-title>
              <b-card-sub-title>
                <small>Catégorie : {{ blog.category.name }}</small>
              </b-card-sub-title>
              <b-media no-body class="mt-2 mb-2">
                <b-media-aside vertical-align="center" class="mr-75 mt-n1">
                  <b-avatar
                    size="30"
                    variant="light-primary"
                    badge
                    :text="
                      avatarText(
                        `${blog.author.first_name} ` +
                          ` ${blog.author.last_name}`
                      )
                    "
                    :src="blog.author.profile_image"
                    class="badge-minimal"
                    badge-variant="success"
                  />
                </b-media-aside>
                <b-media-body>
                  <small class="text-muted mr-50">Rédigé par</small>
                  <small>
                    <b-link class="text-body"
                      >{{ blog.author.last_name }} {{ blog.author.first_name }}
                    </b-link>
                    <br />
                  </small>
                  <small v-if="blog.status == 0" class="text-muted"
                    >Crée le
                    {{
                      moment(blog.created_at).locale("fr").format("llll")
                    }}</small
                  >
                  <small v-else-if="blog.status == 1" class="text-muted"
                    >Validé le
                    {{
                      moment(blog.validation_date).locale("fr").format("llll")
                    }}</small
                  >
                  <small v-else-if="blog.status == 2" class="text-muted"
                    >Publié le
                    {{
                      moment(blog.published_date).locale("fr").format("llll")
                    }}</small
                  ><small v-else class="text-muted"
                    >------
                    </small
                  >
                </b-media-body>
              </b-media>

              <b-card-text class="blog-content-truncate">
                <div
                  class="
                    blog-content
                    d-flex
                    align-items-center
                    justify-content-right
                  "
                  v-html="blog.content.slice(0, 100)"
                />
              </b-card-text>
              <hr />
              <div class="d-flex justify-content-between align-items-center">
                <!-- <b-link :to="{ path: `/pages/blog/${blog.id}#blogComment` }">
                <div class="d-flex align-items-center text-body">
                  <feather-icon icon="MessageSquareIcon" class="mr-50" />
                  <span class="font-weight-bold"
                    >{{ kFormatter(blog.comment) }} Comments</span
                  >
                </div>
              </b-link> -->
                <b-link
                  :to="{
                    name: 'blog-detail',
                    params: { id: blog.slug.concat('-', blog.id) },
                  }"
                  class="font-weight-bold"
                  @click="applyArticleDetail(blog)"
                >
                  Lire plus
                </b-link>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="12">
          <!-- pagination -->
          <div class="d-flex justify-content-between mt-2 flex-wrap">
            <b-button
              :disabled="metaData.prev_page_url == null"
              variant="outline-secondary"
              @click="applyLoadMoreArticle(metaData.prev_page_url)"
            >
              <span class="text-white text-nowrap font-medium-2"
                >Page précédente</span
              >
            </b-button>

            <div>
              <span class="text-white font-medium-2"
                >{{ metaData.current_page }} sur
                {{ metaData.last_page }} pages</span
              >
            </div>
            <div>
              <span class="text-white font-medium-2">Total : {{ total }} </span>
            </div>
            <b-button
              :disabled="metaData.next_page_url == null"
              variant="outline-secondary"
              @click="applyLoadMoreArticle(metaData.next_page_url)"
            >
              <span class="text-nowrap font-medium-1 text-white"
                >Page suivante</span
              >
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BButton,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BSpinner,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    BSpinner,
  },
  data() {
    return {
      blogList: [],
      avatarText,
      isReLoadArticles: false,
    };
  },
  watch: {
    isBlogArticlesLoading(val) {
      if (val == false) {
        this.blogList = this.blogsArticles.data;
      }
    },
  },
  mounted() {},
  created() {
    if (this.blogsArticles.data.length !== 0) {
      this.blogList = this.blogsArticles.data;
    } else {
      this.applyGetBlogArticlesAction();
    }
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
    ...mapGetters(["isBlogArticlesLoading"]),
    ...mapGetters("blog", ["blogsArticles"]),

    metaData() {
      const meta = {
        prev_page_url: "",
        next_page_url: "",
        current_page: "",
        last_page: "",
        current_page_url: "",
      };
      if (this.blogsArticles != null) {
        meta.prev_page_url = this.blogsArticles.prev_page_url;
        meta.next_page_url = this.blogsArticles.next_page_url;
        meta.current_page = this.blogsArticles.current_page;
        meta.last_page = this.blogsArticles.last_page;
        meta.current_page_url = `${this.blogsArticles.path}?page=${this.blogsArticles.current_page}`;
        this.total = this.blogsArticles.total;
      }
      return meta;
    },
  },

  methods: {
    ...mapActions("blog", ["getAllArticlesAction", "loaadMoreArticlesAction"]),
    resolveArticleStatusVariant: (status) => {
      if (status === 1) return "info";
      if (status === 0) return "danger";
      if (status === 2) return "success";
      if (status === 3) return "warning";
    },
    resolveArticleStatusText: (status) => {
      if (status === 1) return "Validé";
      if (status === 0) return "Non validé";
      if (status === 2) return "Publié";
      if (status === 3) return "Rejeté";
    },
    applyGetBlogArticlesAction() {
      this.getAllArticlesAction()
        .then(() => {
          this.$store.commit("SET_IS_BLOGS_ARTICLES_LOADING", false);
        })
        .catch(() => {
          this.$store.commit("SET_IS_BLOGS_ARTICLES_LOADING", false);
        });
    },
    reLoadArticlesAction() {
      this.isReLoadArticles = true;
      this.$store.commit("SET_IS_BLOGS_ARTICLES_LOADING", true);
      this.blogList = [];
      this.getAllArticlesAction()
        .then(() => {
          this.isReLoadArticles = false;
          this.$store.commit("SET_IS_BLOGS_ARTICLES_LOADING", false);
        })
        .catch(() => {
          this.isReLoadArticles = false;
          this.$store.commit("SET_IS_BLOGS_ARTICLES_LOADING", false);
        });
    },
    applyLoadMoreArticle(url) {
      this.$store.commit("SET_IS_BLOGS_ARTICLES_LOADING", true);
      this.blogList = [];
      this.loaadMoreArticlesAction(url)
        .then(() => {
          this.$store.commit("SET_IS_BLOGS_ARTICLES_LOADING", false);
        })
        .catch(() => {
          this.$store.commit("SET_IS_BLOGS_ARTICLES_LOADING", false);
        });
    },
    applyArticleDetail(blog) {
      this.$store.commit("blog/SET_BLOG_ARTICLE", blog);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style scoped>
.card-img-top {
  object-fit: cover !important;
  height: 250px;
}
</style>
